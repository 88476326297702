.qqk_con {
    width: 100%;
    margin: 0 auto;
}

.qqkTtitle {
    font-size: 2.75vw;
    font-weight: 600;
    text-align: center;
    font-family: "Montserrat", sans-serif;
}

.qqkBoxs {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.qqkBoxs a {
    text-decoration: none;
    list-style-type: none;
    color: black;
}

.qqkBox {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.qqkBoxs_title {
    font-size: 1.3vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

.qqkBox span:nth-child(2) {
    font-size: 1.1vw;
    font-family: "Montserrat", sans-serif;
}

.qqkBox span:nth-child(3) {
    color: #848E9F;
    font-size: 1.2vw;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    border-top: 1px solid #848e9f73;
    padding: 1vw 0;
    margin-top: 1vw;
} 

.QqkChil {
    width: 80%;
    margin: 0 auto;
    position: relative;
}

.QqkChil_title {
    font-size: 1.5vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 0;
    margin: 0;
    margin-top: 2vw;
}

.QqkChil_date {
    width: 100%;
    color: #848E9F;
    font-size: .9vw;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;    
}

.QqkChil_text {
    font-family: "Montserrat", sans-serif;    
}

.QqkChil_view {
    position: absolute;
    right: .1vw;
    top: 1.9vw;
    font-family: "Montserrat", sans-serif;  
    display: flex;
    align-items: center;
    gap: .3vw;
    font-size: 1vw;
    font-weight: 600;
    color: #848E9F;
}

.lineQqkChil{ 
    width: 100%;
    height: .2vw;
    background: transparent;
    position: absolute;
    border-bottom: 1px solid #848E9F;
    left: 0;
    top: 3vw;
}