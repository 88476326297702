@font-face {
  font-family: zonaProThin;
  src: url(./font/zonaprothin.ttf);
}

@font-face {
  font-family: zonaProBold;
  src: url(./font/zonaprobold.ttf);
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
  min-height: 100vh;
  padding-top: 6.944vw;
  background: white;
}

/* CSS for fade transition */
.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  opacity: 1;
  transform: scale(1);
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
