@font-face {
    font-family: 'zonaProThin';
    src: url(../../../font/zonaprothin.ttf);
}

@font-face {
    font-family: 'zonaProBold';
    src: url(../../../font/zonaprobold.ttf);
}




.mainnewspos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 114px;
}


.mainnewscontent {
    width: 1000px;
    height: 731px; 
    border-radius: 32px;
    background-color: #F1F1F1 ;
    font-family: "Montserrat", sans-serif;
}


.mainnewscontent img {
    width: 952px;
    height: 439px;
    margin: 24px;
    border-radius: 12px;
}


.mainnewscontent h3 {
    margin: 0 24px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
}

.mainnewscontent p{
    margin: 0 24px 24px;
    
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}


.news_data {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 16px 24px;
    height: 16px;
    gap: 776px;
}



.datapos {
    display: flex;
    flex-direction: row;
}


.datapos img {
    width: 16px;
    height: 16px;
} 