@font-face {
  font-family: 'zonaProBold';
  src: url(../../font/zonaprobold.ttf);
}





#footer {
  background-color: #060919DD;
  font-family: 'zonaProBold', sans-serif;
  color: #fff;
  width: 100%;
  padding-top: 2.778vw;
}

.footerCon {
  width: 90%;
  margin: 0 auto;
}


.footer-logo {
  width: 21.319vw;
  height: 7.431vw;
}


/* Menu links */

.footer_h1_menu {
  margin-bottom: 1.111vw;
  font-family: 'zonaProBold', sans-serif;
  color: #FFFF;
}



.footerUl {
  width: 60%;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  gap: 1.389vw;
}

.footerUl li {
  color: #fff;
  text-decoration: none;
  font-size: 1.111vw;

}

.footerUl a {
  display: inline-block;
  flex-direction: row;
  text-decoration: none;
}


.fixpost {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Footer links and hr border */

.footer-store-links {
  width: 90%;
  margin: 3.472vw auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;  
}

.store-link {
  width: 13.806vw;
  height: 60px;
  border-radius: 1.111vw;
  margin-right: 0.694vw;
}


.store-links2 {
  margin-top: 0.833vw;
  margin-left: 10.417vw;
}

.store-links2 img {
  cursor: pointer;
}

.store-links2 img:hover {
  background: #a298984f;
  border-radius: 2.083vw;
}

#hrborder {
  width: 90%;
  margin-top: 2.778vw;
  margin-bottom: 5.833vw;
  border: none;
  border-top: 0.278vw solid #FB3505;
}

.btnApp {
  display: flex;
}

@media screen and (max-width: 768px) { 
  .footer-logo {
    width: 70vw;
    height: auto;
  }
  .store-links2 {
    display: none;
  }
  .footerUl li {
    font-size: 4vw;
  }
  .btnApp {
    display: flex;
    flex-direction: column;
  }
  .btnApp img {
    width: 40vw;
  }
  .footerUl a {
    font-size: 3.5vw;
  }
  .footer_h1_menu {
    font-size: 6.5vw;
  }
}