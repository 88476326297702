

.ustav {
    width: 90%;
    margin: 0 auto;
}

.ustavImgs {
    width: 90%;
    overflow-y: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.389vw;
    margin: 2.778vw;
}

.ustavImgs img {
    width: 44.722vw;
}

@media screen and (max-width: 768px) {
    .ustavImgs {
        flex-direction: column;
    }
    .ustavImgs img {
        width: 100%;
    }
}