.rules {
    width: 90%;
    margin: 0 auto;
}

.rulesmaintext {
    text-align: center;
    font-family: "Montserrat", sans-serif;
}

.pdfOpener {
    display: flex;
    align-items: center;
    justify-content: center;
}


.responsiveIframe {
    width: 50%; /* Desktop o'lchami */
}

@media (max-width: 768px) { /* Mobil/responsiv holat */
    .responsiveIframe {
        width: 100%; /* Mobil ekranda to'liq kenglik */
    }
}
