@font-face {
    font-family: "zonaProThin";
    src: url(../../../font/zonaprothin.ttf);
}

@font-face {
    font-family: "zonaProBold";
    src: url(../../../font/zonaprobold.ttf);
}

.moreNewspos {
    display: flex;
    justify-content: center;
    align-content: center;
}

.bignewshead {
    margin-bottom: 36px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 54px;
    line-height: 63px;
    text-transform: uppercase;
}

.allbtn {
    width: 95px;
    height: 72px;
    padding: 24px 32px;
    border-radius: 60px;
    color: #ffff;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    background: linear-gradient(#ff9b00, #ed1c24);
    border: 1px solid #ff9b00;
    margin-top: 15px;
}

.titlepos {
    display: flex;
    flex-direction: row;
    gap: 500px;
}

/*   News Block    */
.newsblocpos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 120px;
    margin-right: 120px;
    margin-bottom: 114px;
    padding: 0;
    gap: 16px;
}

.newsbloc {
    width: 420px;
    height: 590px;
    background-color: #f1f1f1;
    border-radius: 32px;
    font-family: "Montserrat", sans-serif;
}

.newsbloc img {
    width: 372px;
    height: 439px;
    margin: 24px;
    margin-bottom: 16px;
    border-radius: 12px;
}

.newsbloc h3 {
    width: 372px;
    height: 55px;
    margin: 0 24px;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
}

.datapublication {
    height: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    gap: 212px;
}

.datapublics {
    display: flex;
    flex-direction: row;
}

.datapublication img {
    width: 16px;
    height: 16px;
}

.morenewsbtn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.morenewsbtn button {
    width: 876px;
    height: 72px;
    padding: 24px, 32px, 24px, 32px;
    border-radius: 60px;
    background: linear-gradient(#ff9b00, #ed1c24);
    color: white;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

/* Templ */
.infocont {
    background-color: #ffff;
}

.iresurce_con {
    width: 90%;
    margin: 100px 120px 100px 120px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.iresurce_title {
    font-family: "Montserrat", sans-serif;
    font-size: 54px;
    font-weight: 700;
    color: #000000;
    text-transform: uppercase;
    margin: 0;
    padding: 20px 0;
}
