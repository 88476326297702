@font-face {
    font-family: 'zonaProBold';
    src: url('../../font/zonaprobold.ttf'); 
}

@font-face {
    font-family: 'zonaProThin';
    src: url('../../font/zonaprothin.ttf');
 }


 
.pagebackground {
    display: flex;
    justify-content: center ;
    align-items: center;
    padding: 0 60px;
}

.inlineheadthree {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.BackOfForm {
    width: 90%;
    height: 100%;
    background-color: white;
    margin: 36px 120px;
    border-radius: 32px;
    
}


#onlinedoc {
    margin: 60px 60px 40px 60px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 54px;
    line-height: 63.45px;
    text-align: center;
}

.redassterics {
    color: red;
}

.formst {
    padding: 0 60px 40px;
}


.fixpos {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.fiobtn {
    display: flex;
    flex-direction: row;
    gap: 24px;
    }

.fiobtn input {
    width: 334px;
    height: 56px;
    padding-left: 16px;
    border-radius: 8px;
    border: 1px solid #F1F1F1;
    background-color: #F1F1F1;
    color: #000000;
}




#subbutton {
    width: 1005px;
    height: 72px;
    margin-top: 40px;
    color: #FFFFFF;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-weight: 700;
    font-size: 16px;
    border: 1px solid #F657144D;
    border-radius: 60px;
    background: linear-gradient(#FF9B00, #ED1C24);
}

.mainblock {
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    gap: 32px;
}

.bagdoc {
    width: 524px;
    height: 249px;
    padding: 24px ;
    border-radius: 8px;
    background-color: #F1F1F1;
}

.uploading  {
    width: 151px;
    height: 40px;
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center;
    margin: auto;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: white;
    background: linear-gradient(#0157FF, #2CB3FF);
    border-radius: 60px;
}



.bagdoc p {
    font-family:"Montserrat", sans-serif ;
    font-weight: 400;
    font-size: 10px;
}


.bagdoc input[type="file"] {
    position: fixed;
    opacity: 0;
}



.docImg {
    display: flex;
    width: max-content;
    margin: auto;
}

.docImg p {
    margin: 44px 16px;
    font-family:"Montserrat", sans-serif ;
    font-weight: 400;
    font-size: 10px;
}

 



.seria {
    width: 56px;
    height: 56px;
    padding-left: 16px;
    border-radius: 8px;
    border: 1px solid #F1F1F1;
    background-color: #F1F1F1;
    color: #000000;
}



.passnum {
    width: 289px;
    height: 56px;
    margin-left: 24px;
    padding-left: 16px;
    border-radius: 8px;
    border: 1px solid #F1F1F1;
    background-color: #F1F1F1;
    color: #000000;
}


.phonnum {
    width: 224px;
    height: 56px;
    padding-left: 16px;
    border-radius: 8px;
    border: 1px solid #F1F1F1;
    background-color: #F1F1F1;
    color: #000000;
}


.emls {
    width: 280px;
    height: 56px;
    margin-left: 24px;
    padding-left: 16px;
    border-radius: 8px;
    border: 1px solid #F1F1F1;
    background-color: #F1F1F1;
    color: #000000;
}



.dates {
    width: 389px;
    height: 56px;
    padding-left: 16px;
    border-radius: 8px;
    border: 1px solid #F1F1F1;
    background-color: #F1F1F1;
    color: #000000;
}


.sexstyles {
    width: 567px;
    height: 56px;
    border-radius: 8px;
    border: 1px solid #F1F1F1;
    background-color: #F1F1F1;
}


.sexstyles input[type="radio"] {
    position: fixed;
    opacity: 0;
  }

.wrapedLabel {
    margin: auto;
    margin-left: 20px;
    padding: 0;

}

 .sexstyles label {
    display: inline-block;
    height: max-content;
    margin: 5px;
    padding: 15px 86px;
    font-size: 16px;
    border-radius: 4px;
  }


  .sexstyles input[type="radio"]:checked + label {
    background: linear-gradient(#0157FF, #2CB3FF) ;
    color: white;
}


.sexstyles input[type="radio"]:focus + label {
    border: 2px solid #444;
}

.sexstyles label:hover {
    background: linear-gradient(#0157FF, #2CB3FF);
    color: white;
  }


  .liveplace {
    width: 1005px;
    height: 56px;
    padding-left: 16px;
    border-radius: 8px;
    border: 1px solid #F1F1F1;
    background-color: #F1F1F1;
    color: #000000;
  }



/* Direction */
.studydirection {
    width: 1005x;
    height: 56px;
    border-radius: 8px;
    border: 1px solid #F1F1F1;
    background-color: #F1F1F1;
}


.studydirection input[type="radio"] {
    position: fixed;
    opacity: 0;
  }

.wrapedLabel2 {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-left: 20px;
    padding: 0;

}

 .studydirection label {
    display: inline-block;
    width: 138px;
    height: max-content;
    margin: 5px;
    padding: 15px 86px;
    font-size: 16px;
    border-radius: 4px;
  }


  .studydirection input[type="radio"]:checked + label {
    background: linear-gradient(#0157FF, #2CB3FF) ;
    color: white;
}


.studydirection input[type="radio"]:focus + label {
    border: 2px solid #444;
}

.studydirection label:hover {
    background: linear-gradient(#0157FF, #2CB3FF);
    color: white;
  }

