.department {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.department_box {
    width: 100%;
    min-height: 22.917vw;
    border-radius: 2.083vw;
    background: #F1F1F1;
    display: flex;
    gap: 1.389vw;
    margin: 1vw 0;
    overflow: hidden;
}

.department_box_img img {
    width: 20.833vw;
    height: 100%;
    border-radius: 2.083vw;
}

.department_box_menu {
    display: flex;
    flex-direction: column;
    padding: 1.389vw 0;
    gap: 1.389vw;
}

.department_box_menu a {
    text-decoration: none;
    list-style-type: none;
    color: black;
}

.department_box_menu_title {
    font-size: 2.222vw;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

.department_box_menu ul {
    display: flex;
    flex-direction: column;
    gap: 0.694vw;
    margin: 0;
    padding: 0;
}

.department_box_menu ul a , li {
    list-style-type: none;
    color: black;
    font-size: 1.25vw;
    font-family: "Montserrat", sans-serif;
}

.depChil {
    width: 90%;
    margin: 0 auto;
}

.depChil_main {
    display: flex;
    margin: 2.778vw 0;
    gap: 1.389vw;
}
.saddasd {
    
}
.depChil_main_aside {
    min-width: 22.611vw;
    max-width: 22.611vw;
    height: auto;
    border-radius: 2.222vw;
    background-color: #F1F1F1;
}

.depChil_main_aside a {
    text-decoration: none;
}

.depChil_main_aside p {
    width: 90%;
    font-size: 1.222vw;
    color: #000000;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    margin: 2.083vw;
}

.depChil_main_aside ul {
    padding: 0;
    margin: 2.083vw;
    display: flex;
    flex-direction: column;
    gap: 1.042vw;
}

.depChil_main_aside ul a {
    text-decoration: none;
    list-style-type: none;
}

.depChil_main_aside ul li {
    text-decoration: underline;
    font-size: 1.25vw;
    text-decoration: none;
    list-style-type: none;
    color: rgba(0, 0, 0, 0.6);
}

.depChil_main_aside ul li:hover {
    color: black;
}

.depChil_main_infos {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.depChil_main_infos_userInfo {
    width: 100%;
    height: 23.472vw;
    display: flex;
    align-items: flex-start;
    border: 0.139vw solid #F1F1F1;
    gap: 1.389vw;
    border-radius: 2.222vw;
}

.depChil_main_infos_userInfo img {
    width: 20.833vw;
    height: 100%;
    background-color: #F1F1F1;
    border-radius: 2.222vw;
    object-fit: cover;
}

.depChil_main_infos_userInfo_infos {
    height: 100%;
    margin: 1.389vw;
    font-family: "Montserrat", sans-serif;
}

.depChil_main_infos_userInfo_infos label {
    font-size: 1.25vw;
    font-weight: 400;
    color: black;
}

.depChil_main_infos_userInfo_infos p {
    padding: 0;
    margin: 0;
    font-size: 1.25vw;
    font-weight: 700;
    color: black;
    margin-bottom: 1.736vw;
}

.depChil_main_infos_userInfo_infos p:nth-child(8) {
    text-decoration: underline;
}

.depChil_main_infos_userInfo_infos h2 {
    padding: 0;
    margin: 0;
    font-size: 2.222vw;
    font-weight: 700;
    color: black;
    margin-bottom: 1.389vw;
    text-transform: uppercase;
}

@media screen and (max-width: 768px) {
    .depChil_main_infos_userInfo_infos {
        margin-top: 2vw;   
        margin-left: 3vw;
        margin-bottom: 2vw;
        gap: 0;
    }
    .depChil_main_infos_userInfo_infos h2 {
        margin-bottom: 0;
    }
    .depChil_main_infos_userInfo_infos p {
        margin-bottom: 0;
    }
    .depChil_main_infos {
        margin-top: 4vw;
    }
    .department_box {
        flex-direction: column;
        margin-top: 5vw;
        border-radius: 5vw;
    }
    .department_box_img img {
        width: 100%;
    }
    .department_box_menu {
        padding: 3vw;
    }
    .department_box_menu_title {
        font-size: 5vw;
    }
    .department_box_menu ul a, li {
        font-size: 4vw;
        text-decoration: underline;
    }
    .depChil_main {
        flex-direction: column;
    }
    .depChil_main_aside {
        width: 100%;
        border-radius: 5vw;
        padding: 4vw 0;
    }
    .depChil_main_aside div {
        margin: 1vw 2vw;
    }
    .depChil_main_aside p {
        font-size: 4vw;
    }
    .depChil_main_aside ul li {
        font-size: 3vw;
        text-decoration: underline;
    }
    .depChil_main_infos_userInfo {
        flex-direction: column;
        height: auto;
        border-radius: 5vw;
    }
    .depChil_main_infos_userInfo img {
        width: 100%;
        border-radius: 5vw;
    }
    .depChil_main_infos_userInfo_infos label {
        font-size: 3vw;
    }
    .depChil_main_infos_userInfo_infos h2 {
        font-size: 4vw;
    }
    .depChil_main_infos_userInfo_infos p {
        font-size: 4vw;
    }
}