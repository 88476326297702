.elonlar{
    display: flex;
    flex-direction: column;
    gap: 1vw;
    width: 80%;
    margin: 0 auto;
}

.elonlar_title {
    font-size: 1.75vw;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    text-align: center;
}

.elon_siper_con {
    /* width: 80%; */
    /* margin: 0 auto; */
}   

.elon_siper_con img {
    width: 30vw;
}

.elonmenuNAv button {  
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    background: transparent;
    gap: 0.5vw;
    font-size: 0.972vw;
    font-weight: 400;
}   

.elonmenuNAv button:hover {
    color: #f23c1c;
}

@media screen and (max-width: 768px) {
    .elonlar {
        margin-top: 15vw;
    }
    .elonlar_title {
        font-size: 4vw;
    }
    .elon_siper_con img  {
        width: 100%;
    }
}