.entposon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 215px 260px;
}

.enterence_btn {
    margin-top: 40px;
}

#hemis {
    margin-left: 268px;
}
#rss {
    margin-top: 40px;
    margin-left: 16px;
}