.napravleniya {
    margin-top: 2vw;
    display: flex;
    flex-direction: column;
    gap: 3vw;
}

.study_btnAnim_line2 {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1vw;
}

.study_btnAnim_line2 button {
    padding: 1.111vw 2.778vw 1.111vw 2.778vw;
    border-radius: 6.667vw;
    display: flex;
    align-items: center;
    gap: 0.694vw;
    font-weight: 700;
    color: #000000;
    font-family: "Montserrat", sans-serif;
    background: #F1F1F1;
    border: none;
}

.study_btnAnim_line2 button img {
    width: 2.5vw;
}

@media screen and (max-width: 768px) {
    .napravleniya {
        margin-top: 15vw;
    }
    .study_btnAnim_line2 {
        align-items: center;
        gap: 3vw;
        margin: 5vw auto;
    }
    .study_btnAnim_line2 button {
        width: auto;
        font-size: 4vw;
        padding: 4.111vw 6.778vw;
        border-radius: 20vw;
    }
    .study_btnAnim_line2 button img {
        width: 7vw;
    }
}