.loader { 
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8vw;
}

.icon img:nth-child(1) {
  width: 20vw;
  z-index: 1;
  /* background-color: white; */
}

.icon img:nth-child(2) {
  position: relative;
  width: 10vw; 
  top: -14vw;
  animation: animation360 infinite ease 4s;
}

@keyframes animation360 {
  0% {
    transform: rotate(0deg);
  }100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .icon {
    margin-top: 40vw;
  }
  .icon img:nth-child(1) {
    width: 40vw;
  }
  .icon img:nth-child(2) {
    width: 18vw; 
    top: -27vw;
  }
}