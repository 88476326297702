

.news_page {
    width: 90%;
    margin: 0 auto;
}

.news_page_main {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.news_page_main_news {
    width: 80%;
    margin: 0 auto;
    padding: 1.389vw;
    display: flex;
    flex-direction: column;
    background-color: #F1F1F1;
    border-radius: 1.389vw;
    gap: 0.694vw;
}


.news_page_main_news img {
    width: 100%;
    height: 30.486vw;
    object-fit: cover;
    border-radius: 1.042vw;
}

.news_page_main_news .title {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 1.25vw;
    color: #060919;
    margin: 0;
}

.date_view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    margin: 0;
}

.date_view p {
    margin: 0;
}

.date_view span {
    display: flex;
    align-items: center;
    gap: 0.278vw;
    margin: 0;
}

.news_page_main_news .desc {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1.111vw;
    color: #060919;
}

.moreNews {
    display: flex;
    flex-direction: column;
    margin: 1.389vw 0;
}

.moreNews_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.moreNews_header h1 {
    font-size: 3.75vw;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    text-transform: uppercase;
}

.moreNews_header button {
    padding: 1.667vw 2.222vw;
    background: radial-gradient(127.68% 423.02% at 31.82% -13.39%, #FF9B00 12.5%, #ED1C24 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    color: white;
    font-size: 1.111vw;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    border: none;
    border-radius: 4.167vw;
    box-shadow: 0px 1.042vw 2.5vw 0px #F657144D;
    cursor: pointer;
}

.moreNews_main {
    display: flex;
    overflow: auto;
    gap: 1.736vw;
}

.moreNews_main::-webkit-scrollbar {
    display: none;
}

.moreNews_main_news {
    padding: 1.042vw;
    min-width: 26.389vw;
    max-width: 26.389vw;
    height: 40.972vw;
    background-color: #F1F1F1;
    border-radius: 1.389vw;
}

.moreNews_main_news a {
    text-decoration: none;
    color: black;
    list-style-type: none;
}

.moreNews_main_news img {
    width: 100%;
    height: 30.486vw;
    object-fit: cover;
    border-radius: 1.042vw;
}

.moreNews_main_news .title {
    font-family: "Montserrat", sans-serif;
    font-size: 1.25vw;
    font-weight: 700;
    text-decoration: none;
    list-style-type: none;
}

/* news all */

.newsAll {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.newsAll_title {
    font-size: 3.75vw;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    text-align: center;
}

.newsAll_main {
    width: 65%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.389vw;
}

.newsAll .moreNewsBtn {
    width: 65%;
    margin: 2.083vw auto;
    padding: 1.667vw 0;
    border-radius: 4.167vw;
    background: radial-gradient(127.68% 423.02% at 31.82% -13.39%, #FF9B00 12.5%, #ED1C24 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    box-shadow: 0px 1.042vw 2.5vw 0px #F657144D;
    border: none;
    font-size: 1.111vw;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: #fff;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .news_page {
        width: 100%
    }
    .newsAll {
        width: 100%;
    }
    .newsAll_main {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.389vw;
    }
    .news_page_main {
        margin: 5vw 0;
    }
    .news_page_main_news {
        width: 85%;
        padding: 5vw;
        border-radius: 5.389vw;
    }
    .news_page_main_news img {
        height: 100vw;
        border-radius: 2.389vw;
    }
    .news_page_main_news .title {
        font-size: 3.344vw;
    }
    .moreNews_header button {
        padding: 3.125vw 4.167vw;
        font-size: 3.111vw;
        border-radius: 8.167vw;
    }
    .moreNews_header {
        width: 90%;
        margin: 5vw auto;
    }
    .moreNews_header h1 {
        font-size: 4.167vw;
    }
    .moreNews_main_news {
        padding: 5.042vw;
        min-width: 90%;
        max-width: 90%;
        height: 70vh;
        border-radius: 5.389vw;
    }
    .moreNews_main_news img {
        width: 100%;
        height: 100.486vw;
    }
    .moreNews_main_news .title {
        font-family: "Montserrat", sans-serif;
        font-size: 3vw;
    }
    .newsAll_title {
        font-size: 7.75vw;
    }
    .date_view p {
       font-size: 2.9vw;
    }
    
    .date_view span {
        font-size: 2.9vw;
    }
    .news_page_main_news .desc {
        font-size: 3.1vw;
    }
    .newsAll .moreNewsBtn {
        padding: 4.667vw 0;
        font-size: 3.1vw;
        border-radius:  8.667vw;
    }
}