

.yonalish {
    width: 90%;
    margin: 0 auto;
    font-family: "Montserrat", sans-serif;
}


.categoryYonlish_title {
    font-size: 3.75vw;
    font-weight: 700;
    color: #000000;
    text-align: center;
    text-transform: uppercase;
}

.categoryYonlish_lists {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    border-radius: 1.042vw;
    overflow: hidden;
    border: 0.069vw solid #F1F1F1;
    margin: 3.472vw 0;
}

.categoryYonlish_lists_list {
    width: 100%;
    height: 4.167vw;
    display: flex;
    align-items: center;
    gap: 1.389vw;
}

.categoryYonlish_lists_list:nth-child(even) {
    background-color: #F1F1F1;
}

.categoryYonlish_lists_list:nth-child(1) {
    background-color: #FB3505;
}

.categoryYonlish_lists_list:nth-child(1) span {
    color: white;
}


.categoryYonlish_lists_list:nth-child(1) span:nth-child(2) {
    text-align: center;
}

.categoryYonlish_lists_list span {
    font-size: 1.25vw;
    font-weight: 700;
}

.categoryYonlish_lists_list span:nth-child(1) {
    width: 13.889vw;
    margin-left: 1.389vw;
    height: 100%;
    border-right: 0.139vw solid white;
    display: flex;
    align-items: center;
}

.categoryYonlish_lists_list span:nth-child(2) {
    flex-grow: 1;
}

.categoryYonlish_lists_list span:nth-child(3) {
    margin-right: 1.389vw;
    width: 13.889vw;
    height: 100%;
    border-left: 0.139vw solid white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eslatma {
    text-align: center;
    color: red;
}

@media screen and (max-width: 768px) {
    .categoryYonlish_lists {
        border-radius: 5vw;
    }
    .categoryYonlish_title {
        font-size: 6vw;
    }
    .categoryYonlish_lists_list {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        padding: 4vw 4vw;
    }
    .categoryYonlish_lists_list span:nth-child(1) {
        border: none;
        margin: 0;
    }
    .categoryYonlish_lists_list span:nth-child(3) {
        border: none;
        margin: 0;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .categoryYonlish_lists_list span {
        font-size: 4vw;
    }
    .categoryYonlish_lists_list span:nth-child(1) {
        width: 100%;
    }
    .categoryYonlish_lists_list span:nth-child(3) {
        width: 100%;
    }
}