@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Rubik:ital,wght@0,300..900;1,300..900&family=Ysabeau+Office:ital,wght@0,1..1000;1,1..1000&display=swap');
.swiper {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding-bottom: 0.347vw;
}

.swiper-slide {
    text-align: center;
    font-size: 1.25vw;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.homeSwiper {
    width: 100%;
    height: 60vh;
    background-color: #fff;
    margin-bottom: 80px;
    transform: translateY(-20px);
    padding: 80px 0;
}

.homeSwiper p {
    text-align: center;
    font-weight: 500;
    font-size: 3.889vw;
    font-family: "Rubik", sans-serif;
    text-transform: uppercase;
}
.homeSwiper .mySwiper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.homeSwiper .mySwiper img {
    border-radius: 1.111vw;
}

.homeSwiper .mySwiper div:nth-child(1) {
    display: flex;
    align-items: center;
}

.instaAnimation {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6.944vw;
    padding-bottom: 1.389vw;
}

.instaAnimation img:nth-child(1) {
    width: 13.889vw;
}

.instaAnimation img:nth-child(2) {
    width: 3.472vw;
}

.instaAnimation p {
    padding: 0;
    margin: 0;
    font-family: sans-serif;
}

@media screen and (max-width: 768px) { 
    .homeSwiper p  {
        width: 80%;
        margin: 0 auto;
        font-size: 8.889vw;
        margin-bottom: 3vw;
    }
    .instaAnimation {
        margin: 0vw 0;
        margin-bottom: 40vw;
    }
    .homeSwiper .mySwiper img {
        border-radius: 3.1vw;
    }
    .instaAnimation img:nth-child(1) {
        width: 42vw;
    }
    .instaAnimation img:nth-child(2) {
        width: 13.472vw;
    }
}