



.Otdels {
    width: 90%;
    margin: 0 auto;
}

.Otdels_boxes {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.389vw;
    margin: 2.778vw 0;
}

.Otdels_box {
    width: 100%;
    height: 23.472vw;
    border: 0.139vw solid #F1F1F1;
    display: flex;
    align-items: center;
    border-radius: 2.222vw;
    gap: 2.083vw;
}

.Otdels_box img {
    width: 20.833vw;
    height: 100%;
    background-color: #F1F1F1;
    border-radius: 2.222vw;
    object-fit: cover;
}

.Otdels_box_infos {
    display: flex;
    flex-direction: column;
}

.Otdels_box_infos h2 {
    font-size: 2.222vw;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
}


.Otdels_box_infos label {
    font-size: 1.25vw;
    color: #000000;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
}

.Otdels_box_infos {
    margin: 2.083vw 0;
}

.Otdels_box_infos p {
    margin: 0.174vw 0;
    padding: 0;
    font-size: 1.25vw;
    color: #000000;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
}

.Otdels_box_infos_contect {
    display: flex;
    gap: 4.167vw;
}

@media screen and (max-width: 768px) {
    .Otdels_box {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 5vw;
    }
    .Otdels_box img {
        width: 100%;
        border-radius: 5vw;
    }
    .Otdels_box_infos h2 {
        font-size: 4vw;
    }
    .Otdels_box_infos label {
        font-size: 3vw;
    }
    .Otdels_box_infos_contect {
        flex-direction: column;
    }
    .Otdels_box_infos p {
        font-size: 4vw;
    }
    .Otdels_box_infos {
        margin: 2vw;
        padding: 1vw 0;
    }
}