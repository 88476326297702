@font-face {
    font-family: 'zonaProBold';
    src: url(../../font/zonaprobold.ttf);
}


.headpos {
    margin-bottom: 40px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 54px;
    text-align: center;
    text-transform: uppercase;

}

.fixtpos {
    margin-bottom: 134px;
}


.tabl {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36vh;
}


table {
    width: 90%;
    border: 5px solid #ffff;
    border-radius: 24px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 18px;
}



table th {
    width: 768px;
    height: 61px;
    background-color: #FB3505;
    color: #FFFF;
    text-align: center;
}

table tbody td {
    padding: 24px;
}



tr:nth-child(odd) {background-color: #F1F1F1;}