@font-face {
    font-family: 'zonaProThin';
    src: url(../../font/zonaprothin.ttf);
}







.btn_position {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Центрирование по горизонтали */
    align-items: center; /* Центрирование по вертикали */
    margin: 36px 120px;
    gap: 16px;
    
}





.btns {
    background: #F1F1F1;
    border:1px solid #F1F1F1;
    border-radius: 48px;
    font-family: 'zonaProBold', sans-serif;
    font-weight: 700;
    text-decoration: none;
    color: black;
    padding: 20px;    
}

  



.btns:hover, .btns:active  {
    color: white;
    background: #FB3505;
    border:1px solid #FB3505;
}



  
