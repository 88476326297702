nav {
    width: 90%;
    height: 5.556vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 7.5vw 0px #0000001A;
    border-radius: 5.556vw;
    position: fixed;
    z-index: 99;
    top: 2%;
    left: 5%;
    backdrop-filter: blur(1vw);
    /* Blur effekti */
    background-color: rgba(255, 255, 255, 0.719);
    /* Fonsiz effekt uchun orqa fon rangi va qatnashish o'rni (opacity) */
    border: 0.069vw solid white;
}


.nav_left {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.389vw;
    margin-left: 2.083vw;
}

.nav_left img {
    width: 9.028vw;
}

.nav_right {
    display: flex;
    margin-right: 2.083vw;
}

.menu_drops {
    display: flex;
    gap: 1.042vw;
}

.menu_drops .menu_drop {
    display: flex;
    position: relative;
}

.menu_drop_btn1 {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
}

.menu_drop_btn1 p {
    font-size: 0.972vw;
    font-weight: 400;
}

.menu_drop_btn1:focus p {
    color: #F23C1C;
}

.menu_drop_btn1:hover p {
    color: #F23C1C;
}

.menu_drop_btn1 svg {
    font-size: 1.528vw;
}

.menu_drop_btn1:focus svg {
    transition: 0.4s;
    transform: rotateX(180deg);
}

.menu_drop_btn1:hover svg {
    transition: 0.4s;
    transform: rotateX(180deg);
}

/* .menu_drops .menu_drop div {
    display: none;
} */

nav .search {
    display: flex;
    align-items: center;
}

nav .search button {
    border: none;
    background: none;
    display: flex;
    align-items: center;
}

nav .search button svg {
    font-size: 1.667vw;
}


nav .langugeSelect {
    display: flex;
    align-items: center;
}

nav .langugeSelect button:nth-child(1) {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
}

nav .langugeSelect button:nth-child(1) p {
    color: #000000;
    font-weight: 700;
    font-size: 0.972vw;
}

nav .langugeSelect button:nth-child(1) svg {
    font-size: 1.528vw;
}


nav .langugeSelect .langugeSelect_menu {
    width: 10vw;
    background-color: white;
    box-shadow: 1px 0px 10px 1px rgba(0, 0, 0, 0.362);
    transform: translateY(7vw);
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 1vw;
}

nav .langugeSelect .langugeSelect_menu img  {
    width: 1.5vw;
}

nav .langugeSelect .langugeSelect_menu button {
    width: 100%;
    border: none;
    background: transparent;
    padding: 1vw;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 1vw;
    gap: 10px;
    cursor: pointer;
}

nav .langugeSelect .langugeSelect_menu button:hover {
    color: #ED1C24;
}


nav .login {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.778vw;
    width: 7.639vw;
    border: none;
    border-radius: 1.736vw;
    background: white;
    color: black;
    font-size: 1.111vw;
    font-weight: bold;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s ease;
    border: 0.069vw solid #F23C1C;
}

nav .login:hover {
    background: linear-gradient(to right, #f7a11a, #F23C1C);
}

nav .login p {
    transform: translateX(0.694vw);
    transition: .3s;
}

nav .login:hover p {
    transform: translateX(1.944vw);
    color: white;
}

nav .login div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.778vw;
    height: 2.778vw;
    background: linear-gradient(0deg, #FB3505, #FB3505),
        radial-gradient(127.68% 423.02% at 31.82% -13.39%, #FF9B00 12.5%, #ED1C24 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    border-radius: 50%;
    transition: all 0.3s ease;
    position: relative;
    transform: translateX(0.417vw);
}

nav .login img {
    width: 1.389vw;
    height: 1.389vw;
    transition: all 0.3s ease;
}

nav .login:hover div {
    transform: translateX(3.472vw);
}

/* nav category dropdown style */

.menu_drop div {
    position: absolute;
    transform: translateY(2.778vw);
    min-width: calc(100% + 10.417vw);
    height: auto;
    padding: 0.694vw 0.694vw;
    background-color: #F1F1F1;
    border-radius: 1.042vw;
    border: 0.139vw solid var(--222, #FFFFFF);
    box-shadow: 0px 0px 7.5vw 0px #0000001A;
}

.nav_right a {
    text-decoration: none;
}

/* .menu_drop div:nth-child(2) {
    min-width: calc(100% + 230px);
} */

.menu_drop div button {
    width: 100%;
    height: 2.847vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: start;
    white-space: nowrap;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border: none;
    font-size: 1.111vw;
    padding: 0 1.042vw;
    margin: 0;
    border-radius: 0.833vw;
    text-decoration: none;
    list-style-type: none;
}

.menu_drop div a {
    text-decoration: none;
    list-style-type: none;
}

.menu_drop div button:hover {
    background: white;
}

.menu_drop div button p {
    flex-grow: 0;
    /* Matnning kengligini o'zgarishini ta'minlash */
    transition: flex-grow 0.5s;
    /* Matnning kengligini animatsiyalash */
    text-decoration: none;
    list-style-type: none;
}

.menu_drop div button svg {
    transition: 0.5s;
    position: relative;
    transform: translateX(0);
    opacity: 0;
    color: #FB3505;
    font-size: 1.319vw;
}

.menu_drop div button:hover p {
    flex-grow: 1;
    /* Matnning kengligini 0 ga o'zgartirish */
    color: #FB3505;
}

.menu_drop div button:hover svg {
    opacity: 1;
    transform: translateX(0);
    /* SVG elementining harakatlanishi */
    color: #FB3505;

}




@media screen and (max-width: 768px) {
    .menu_drops {
        display: none;
    }

    nav  .langugeSelect {
        width: 20vw;
    }

    nav .langugeSelect .langugeSelect_menu {
        width: 30vw;
        font-size: 4vw;
        transform: translateY(15vw);
    }

    nav .langugeSelect .langugeSelect_menu button {
        font-size: 4vw;
        align-items: flex-start;
    }
    nav .langugeSelect .langugeSelect_menu button:nth-child(3) {   
        transform: translateX(-2vw);
    }

    nav .langugeSelect .langugeSelect_menu img {
        width: 4vw;
    }

    nav {
        height: 13.89vw;
        /* Original: 5.556vw */
        box-shadow: 0px 0px 18.75vw 0px #0000001A;
        /* Original: 7.5vw */
        border-radius: 13.89vw;
        /* Original: 5.556vw */
        backdrop-filter: blur(1.735vw);
        /* Original: 0.694vw */
        border: 0.173vw solid white;
        /* Original: 0.069vw */
    }

    .nav_left {
        gap: 3.472vw;
        /* Original: 1.389vw */
        margin-left: 5.208vw;
        /* Original: 2.083vw */
    }

    .nav_left img {
        width: 22.57vw;
        /* Original: 9.028vw */
    }

    .nav_right {
        margin-right: 5.208vw;
        /* Original: 2.083vw */
    }

    .menu_drops {
        gap: 2.605vw;
        /* Original: 1.042vw */
    }


    .menu_drop_btn1 p {
        font-size: 2.43vw;
        /* Original: 0.972vw */
    }

    .menu_drop_btn1 svg {
        font-size: 3.82vw;
        /* Original: 1.528vw */
    }

    nav .search button svg {
        font-size: 5.167vw;
        /* Original: 1.667vw */
        margin: 0 2vw;
    }

    nav .langugeSelect button:nth-child(1) p {
        font-size: 2.43vw;
        /* Original: 0.972vw */
    }

    nav .langugeSelect button:nth-child(1) svg {
        font-size: 3.82vw;
        /* Original: 1.528vw */
    }

    nav .login {
        height: 6.945vw;
        /* Original: 2.778vw */
        width: 19.1vw;
        /* Original: 7.639vw */
        border-radius: 4.34vw;
        /* Original: 1.736vw */
        font-size: 2.778vw;
        /* Original: 1.111vw */
        border: 0.173vw solid #F23C1C;
        /* Original: 0.069vw */
    }

    nav .login p {
        transform: translateX(1.735vw);
        /* Original: 0.694vw */
    }

    nav .login:hover p {
        transform: translateX(4.86vw);
        /* Original: 1.944vw */
    }

    nav .login div {
        width: 6.945vw;
        /* Original: 2.778vw */
        height: 6.945vw;
        /* Original: 2.778vw */
        transform: translateX(2.1vw);
        /* Original: 0.417vw */
    }

    nav .login img {
        width: 3.472vw;
        /* Original: 1.389vw */
        height: 3.472vw;
        /* Original: 1.389vw */
    }

    nav .login:hover div {
        transform: translateX(8.68vw);
        /* Original: 3.472vw */
    }

    /* nav category dropdown style */

    .menu_drop div {
        transform: translateY(6.945vw);
        /* Original: 2.778vw */
        min-width: calc(100% + 26.04vw);
        /* Original: 10.417vw */
        padding: 1.735vw 1.735vw;
        /* Original: 0.694vw */
        border-radius: 2.605vw;
        /* Original: 1.042vw */
        border: 0.347vw solid var(--222, #FFFFFF);
        /* Original: 0.139vw */
        box-shadow: 0px 0px 18.75vw 0px #0000001A;
        /* Original: 7.5vw */
    }

    .menu_drop div button {
        height: 7.118vw;
        /* Original: 2.847vw */
        font-size: 2.778vw;
        /* Original: 1.111vw */
        padding: 0 2.605vw;
        /* Original: 1.042vw */
        border-radius: 2.083vw;
        /* Original: 0.833vw */
    }

    .menu_drop div button svg {
        font-size: 3.298vw;
        /* Original: 1.319vw */
    }

}