.fakultetCon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-size: 100%;
    background-position: center;
    overflow-x: hidden;
}

.fakultetConBtn {
    width: 18.6vw;
    height: 5.44vw;
    background: transparent;
    border: none;
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.fakultetConBtn p {
    margin: 0.16vw 0;
    padding: 0;
}

.fakultetConBtn p:nth-child(1) {
    color: white; 
    font-weight: 600;
    font-size: 0.94vw;
    text-transform: uppercase;
    margin-top: 0.94vw;
}

.fakultetConBtn p:nth-child(2) {
    color: white;
    font-weight: 500;
    font-size: 0.76vw;
}

.fakultetConBtns {
    display: flex;
    height: max-content;
    overflow: unset;
}

.fakultetConBtns button {
    cursor: pointer;
}

.fakultetConBtns button:nth-child(1) {
    transform: translateY(-3.12vw) translateX(6.24vw) scale(0.5); 
    filter: blur(0.31vw); 
}

.fakultetConBtns button:nth-child(2) {
    transform: translateY(3.12vw); 
}

/* active line 1 */

.fakultetConBtns .fakultetConBtn-1:nth-child(2)::before {
    content: "";
    position: absolute;
    height: 5.2vw; 
    width: 28.31vw; 
    background-color: transparent;
    top: 4.75vw; 
    left: 9.25vw;
    border-bottom: 0.09vw solid #FB3505;
    border-left: 0.09vw solid #FB3505;
    animation: animLine .5s ease-in-out ;
}

.fakultetConBtns .fakultetConBtn-1:nth-child(2)::after {
    content: "";
    position: absolute;
    top: 9.96vw; 
    left: 32.4vw; 
    width: 5.2vw; 
    height: 15.98vw; 
    border-right: 0.09vw solid #FB3505;
    animation: animLine 1s ease-in-out ;
}

/* active line 2 */

.fakultetConBtns .fakultetConBtn-2:nth-child(2)::before {
    content: "";
    position: absolute;
    height: 7.25vw; 
    width: 9.78vw; 
    background-color: transparent;
    top: 2.65vw; 
    left: 27.85vw;
    border-bottom: 0.09vw solid #FB3505;
    border-left: 0.09vw solid #FB3505;
    animation: animLine .5s ease-in-out ;
}

.fakultetConBtns .fakultetConBtn-2:nth-child(2)::after {
    content: "";
    position: absolute;
    top: 9.96vw; 
    left: 32.4vw; 
    width: 5.2vw; 
    height: 11.98vw; 
    border-right: 0.09vw solid #FB3505;
    animation: animLine 1s ease-in-out ;
}

/* active line 3 */

.fakultetConBtns .fakultetConBtn-3:nth-child(2)::before {
    content: "";
    position: absolute;
    height: 7.9vw; 
    width: 8.85vw; 
    background-color: transparent;
    top: 2vw; 
    left: 37.6vw;
    border-bottom: 0.09vw solid #FB3505;
    border-right: 0.09vw solid #FB3505;
    animation: animLine .5s ease-in-out ;
}

.fakultetConBtns .fakultetConBtn-3:nth-child(2)::after {
    content: "";
    position: absolute;
    top: 9.96vw; 
    left: 32.4vw; 
    width: 5.2vw; 
    height: 11.98vw; 
    border-right: 0.09vw solid #FB3505;
    animation: animLine 1s ease-in-out ;
}

/* active line 4 */

.fakultetConBtns .fakultetConBtn-4:nth-child(2)::before {
    content: "";
    position: absolute;
    height: 2.9vw; 
    width: 27.35vw; 
    background-color: transparent;
    top: 5.5vw; 
    left: 37.7vw;
    border-bottom: 0.09vw solid #FB3505;
    border-right: 0.09vw solid #FB3505;
    animation: animLine .5s ease-in-out ;
}

.fakultetConBtns .fakultetConBtn-4:nth-child(2)::after {
    content: "";
    position: absolute;
    top: 8.40vw; 
    left: 32.4vw; 
    width: 5.2vw; 
    height: 8.98vw; 
    border-right: 0.09vw solid #FB3505;
    animation: animLine 1s ease-in-out ;
}

/* line animation  */

@keyframes animLine {
    0% {
        opacity: 0;
    } 100% {
        opacity: 1;
    }
}




.fakultetConBtns button:nth-child(3) {
    transform: translateY(0.62vw); 
}

.fakultetConBtns button:nth-child(4) {
    transform: translateY(0);
}

.fakultetConBtns button:nth-child(5) {
    transform: translateY(3.76vw); 
}

.fakultetConBtns button:nth-child(6) {
    transform: translateY(-0.62vw) translateX(-10.32vw) scale(0.5); 
    filter: blur(0.31vw);
}

.categoryBtnsChil {
    position: absolute;
    top: 47.46vw; 
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.04vw; 
    animation: animationCategory 0.5s ease-in-out;
}

@keyframes animationCategory {
    0% {
        opacity: 0;
    }100% {
        opacity: 1;
    }
}

.categoryBtnsChil button {
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center; 
    padding: 1.23vw 1.75vw; 
    border-radius: 3.56vw; 
    border: none;
    background: #F1F1F1;
    font-size: 0.94vw; 
    color: black;
    font-weight: 600;
    white-space: nowrap; 
}
