.studies {
    background-color: #FFFFFF;    
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin: 20px 0px;
    margin-top: -10px;
    padding-top: 20px;
}

.studies_title {
    text-align: center;
    color: #060919;
    font-size: 54px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    padding: 0;
    margin: 0;
}

.studies_btnBottm {
    width: 80%;
    height: 72px;
    border-radius: 60px;
    background: radial-gradient(127.68% 423.02% at 31.82% -13.39%, #FF9B00 12.5%, #ED1C24 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    box-shadow: 0px 15px 36px 0px #F657144D;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
}

.studies_btnAnim {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    background-color: #FFFFFF;
}

.studies_btnAnim_line {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.studies_btnAnim_line_R {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.studies_btnAnim_line button {
    padding: 16px 40px 16px 40px;
    border-radius: 96px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    color: #000000;
    font-family: "Montserrat", sans-serif;
    background: #F1F1F1;
    border: none;
}

.studies_btnAnim_line_R button {
    padding: 16px 40px 16px 40px;
    border-radius: 96px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    color: #000000;
    font-family: "Montserrat", sans-serif;
    background: #F1F1F1;
    border: none;
}
