@font-face {
    font-family: 'zonaProBold';
    src: url('../../font/zonaprobold.ttf'); 
  }


  @font-face {
    font-family: 'zonaProThin';
    src: url('../../font/zonaprothin.ttf'); 
  }

  #topMenuBanner {
    margin: 36px 120px 0 120px;
    display: flex;
    border-radius: 32px;
    background-color: #F1F1F1;
}

  .menupsition {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Две колонки */
    grid-template-areas:
      "text image"
      "button image"; /* Определение областей сетки */
  }
  
  #enterText {
    grid-area: text;
    margin: 183px -90px 49px 72px;
    align-content: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 45px;
    line-height: 63.45px;

  }
  
  #enterButton {
    grid-area: button; /* Размещение кнопки в области 'button' */
    margin-left: 72px;
    width: 116px;
    height: 72px;
    background: radial-gradient(#FF9B00,  #ED1C24);
    border: 1px solid #F657144D;
    border-radius: 60px;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.8px;
    color: #F1F1F1;
    box-shadow: 0px 15px 36px 0px #F657144D;
}


.applydocs {
    grid-area: button; 
    margin-left: 72px;
    width: 234px;
    height: 72px;
    background: radial-gradient(#FF9B00,  #ED1C24);
    border: 1px solid #F657144D;
    border-radius: 60px;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    color: #F1F1F1;
    box-shadow: 0px 15px 36px 0px #F657144D;
}
  
  .researchimg {
    grid-area: image; /* Размещение изображения в области 'image' */
    justify-self: end; /* Выравнивание изображения по правому краю */
    align-self: start;  /* Выравнивание изображения по верхнему краю */
    padding: 35px 46px 47px 0px;
  }
  
  .researchimg #studentImage{
    width: 405px;
    height: 518px;
  }
  

  









/*    Templates       */
.infocont {
    background-color: #FFFF;
}

.iresurce_con {
    width: 90%;
    margin: 100px 120px 100px 120px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.iresurce_title {
    font-family: "Montserrat", sans-serif;
    font-size: 54px;
    font-weight: 700;
    color: #000000;
    text-transform: uppercase;
    margin: 0;
    padding: 20px 0;
}

.iresurce_boxes {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.iresurce_boxes_top {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.iresurce_boxes_top_box {
    height: 225px;
    border-radius: 32px;
    background: #F1F1F1;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex: 1;
    box-shadow: 0px 0px 10px 0px #0000001A;
}

.iresurce_boxes_bottom {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.iresurce_boxes_bottom_box {
    height: 225px;
    border-radius: 32px;
    background-color: #F1F1F1;
    display: flex;
    align-items: center;
    flex: 1;
    box-shadow: 0px 0px 108px 0px #0000001A;
}

.iresurce_boxes_bottom_box p {
    width: 50%;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    margin-left: 20px;
}
