
.struktura {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5vw 0;
}

.struktura img {
    width: 80%;
}

@media screen and (max-width: 768px) {
    .struktura {
        margin-top: 20vw;
    }
}