.aboutPage {
    width: 90%;
    margin: 0 auto;
}

.btnHeader {
    width: 90%;
    margin: 1.389vw auto;
    display: flex;
    flex-wrap: wrap;
    gap: 1.389vw;
    align-items: center;
    justify-content: center;
}


.btnHeader button {
    padding: 1.111vw 1.667vw;
    cursor: pointer;
    font-weight: 700;
    font-size: 1.25vw;
    border-radius: 3.333vw;
    border: none;
    background-color: #F1F1F1;
    font-family: "Montserrat", sans-serif;
    transition: 0.3s;
}

.btnHeader .active {
    transition: 0.3s;
    background: #FB3505;
    color: #fff;
}

.aboutPage_main {}

.aboutPage_main_logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 6.944vw;
}

.aboutPage_main_logo img:nth-child(1) {
    width: 16.333vw;
    z-index: 1;
}

.aboutPage_main_logo img:nth-child(2) {
    width: 7.944vw;
    animation: animRotate 4s infinite ease-in;
    position: absolute;
    top: 5vw;
}

@keyframes animRotate {
    0% {
        transform: rotate(0deg); 
    }100% {
        transform: rotate(360deg);
    }
}
.aboutPage_info {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    margin: 3vw;
    font-size: 1vw;
    line-height: 1.9vw;
}

.aboutPage_main_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 6.944vw;
}

.aboutPage_main_title p:nth-child(1) {
    font-size: 1.667vw;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    padding: 0;
    margin: 0;
}

.aboutPage_main_title p:nth-child(2) {
    font-size: 1.25vw;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .aboutPage {
        width: 100%;
        margin: 0 auto;
    }
    .btnHeader {
        width: 100%;
        margin: 15.389vw auto;
        /* gap: 4.389vw; */
        flex-wrap: nowrap;
        overflow-x: auto;
        align-items: center;
        justify-content: flex-start;
        gap: 3.389vw;
        margin-bottom: 0;
    }

    .btnHeader button {
        padding: 4.111vw 4.667vw;
        font-size: 4.25vw;
        border-radius: 8.333vw;
        white-space: nowrap; /* Prevents text from wrapping */
        overflow: hidden;    /* Hides any overflowing text */
        text-overflow: ellipsis; /* Adds "..." if the text overflows */
    }
    
    .aboutPage_main_logo img:nth-child(1) {
        width: 55.333vw;    
    }
    .aboutPage_main_logo img:nth-child(2) {
        top: 16vw;
        width: 26.944vw;
    }
    .aboutPage_main_title  {
        margin-top: 13.021vw;
    }
    .aboutPage_main_title p:nth-child(1) {
        font-size: 4.667vw;
    }
    .aboutPage_main_title p:nth-child(2) {
        font-size: 4.25vw;
    }
    .aboutPage_info {
        font-size: 4vw;
        line-height: 4.5vw;
    }
}