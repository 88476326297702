@font-face {
    font-family: 'zonaProThin';
    src: url(../../font/zonaprothin.ttf);
}

@font-face {
    font-family: 'zonaProBold';
    src: url(../../font/zonaprobold.ttf);
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* right menu */

.rightMenu {
    position: fixed;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: 11;
    transition: transform 1s;
    transform: translateX(110vw);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2.083vw;
}

.rigthMenuOpen {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 11;
    transition: transform 1s;
    transform: translateX(10vw);
}

.rightMenuStudent {
    display: flex;
    max-height: 100vh;
    transition: transform 1s;
}

.rightMenuStudent button {
    width: 6.25vw;
    height: 34.722vw;
    position: relative;
    background: none;
    border: none;
    z-index: 1;
    cursor: pointer;
}

.rightMenuStudent button span {
    position: absolute;
    transform: rotate(270deg) translateX(2.6994vw);
    font-size: 2.8vw;
    font-weight: 700;
    left: -4.48vw;
    text-transform: uppercase;
    background: linear-gradient(90deg, #FF9B00, #ED1C24);
    /* Linear gradient colors */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* Fallback for some browsers */
    color: transparent;
    /* Ensure the text is transparent */
    font-family: "Montserrat", sans-serif;
}

.rightMenuStudent button img {
    position: absolute;
    top: -1.389vw;
    right: 0;
    width: 5.556vw;
}

.rightMenuStudent_menu {
    position: relative;
    top: -5vh;
    width: 110vw;
    height: 104vh;
    background-color: white;
    transition: transform 1s;
    transform: translateX(0vw);
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightMenuStudent_menu_info {
    width: calc(83.333vw - 10vw);
    height: 41.667vw;
    background-color: #F1F1F1;
    transform: translateX(-4vw);
    border-radius: 2.222vw;
    display: flex;
    padding: 0 5vw;
}

.rightMenuStudent_menu_info_left {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.rightMenuStudent_menu_info_left h1 {
    font-size: 3.75vw;
    font-weight: 600;
    color: black;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
}

.rightMenuStudent_menu_info_left button {
    width: 8.333vw;
    height: auto;
    padding: 1.667vw 2.222vw;
    background: radial-gradient(127.68% 423.02% at 31.82% -13.39%, #FF9B00 12.5%, #ED1C24 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    box-shadow: 0px 1.042vw 2.5vw 0px #F657144D;
    font-size: 1.111vw;
    border-radius: 2.222vw;
    color: white;
}

.rightMenuStudent_menu_info_right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightMenuStudent_menu_info_right img {
    width: 80%;
}

.rightMenuMenu {
    position: absolute;
    display: flex;
    top: 57.639vw;
    transition: transform 1s;
    /* transform: translateX(-100vw); */
}

.rightMenuMenu button {
    width: 6.25vw;
    height: 34.722vw;
    position: relative;
    background: none;
    border: none;
    transform: translateY(-35.333vw);
    cursor: pointer;
    z-index: 0;
}

.rightMenuMenu button img {
    position: absolute;
    top: -1.389vw;
    right: 0;
    width: 5.556vw;
}

.rightMenuMenu button span {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: rotate(270deg) translateX(17.5vw) translateY(17.361vw);
    font-size: 2.8vw;
    font-weight: 700;
    left: -1vw;
    text-transform: uppercase;
    color: white;
    /* Ensure the text is transparent */
    font-family: "Montserrat", sans-serif;
    z-index: 111;
}


.rightMenuMenu .noneEffect {
    transition: 1s;
    opacity: 0;
}

.rightMenuMenu_menu {
    position: relative;
    top: -63vw;
    /* left: -100vw; */
    width: 110vw;
    height: 110vh;
    background: linear-gradient(137.55deg, #0057FF 24.62%, #00FFFF 67.2%);
    transition: 1s;
}

.rightMenuMenu_menu img {
    width: 100%;
    position: absolute;
    bottom: 0;
    opacity: .2;
}

.rightMenuMenu_menu_menus {
    position: absolute;
    top: 16%;
    left: 16%;
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    z-index: 11;
    gap: 1.389vw;
}

.rightMenuMenu_menu_menus ul {
    display: flex;
    flex-direction: column;
    gap: 0.347vw;
}

.rightMenuMenu_menu_menus ul a {
    text-decoration: none;
}

.rightMenuMenu_menu_menus ul span {
    font-size: 1.528vw;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: white;
    padding: 0.694vw 0;
}

.rightMenuMenu_menu_menus ul li {
    font-size: 1.111vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    color: #FFFFFF66;
    list-style-type: disc;
    margin-left: 1.736vw;
}

.rightMenuMenu_menu_menus ul li:hover {
    color: white;
    cursor: pointer;
}

.instaLink {
    text-decoration: none;
    color: black;
}

.noneBtn {
    position: absolute;
    transform: translateX(-100vw);
    display: none;
}

.rightMenuStudent .rightMenuCloseBtn {
    position: absolute;
    top: 55%;
    left: -0.347vw;
}

.rightMenuStudent .rightMenuCloseBtn img {
    width: 4.861vw;
}

.rightMenuStudent .rightMenuCloseBtn span {
    font-size: 3vw;
    background: none;
    color: #0257FF;
    -webkit-text-fill-color: #0257FF;
    transform: rotate(0deg) translateX(6.861vw) translateY(-5vw);
}

.rightMenuMenu_menu .rightMenuCloseBtn {
    position: relative;
    top: 115%;
    left: 9.722vw;
}

.rightMenuMenu_menu .rightMenuCloseBtn img {
    opacity: 1;
    width: 4.861vw;
}

.rightMenuMenu_menu .rightMenuCloseBtn span {
    width: auto;
    height: auto;
    position: absolute;
    font-size: 3vw;
    background: none;
    color: #0257FF;
    -webkit-text-fill-color: #0257FF;
    transform: rotate(0deg) translateX(3vw) translateY(-5vw);
}

.btnAnim {
    animation: animBtn 1s ease;
}

@keyframes animBtn {
    0% {
        opacity: 0;
        transform: translateX(-20.833vw);
    }

    25% {
        opacity: 0;
        transform: translateX(-10.417vw);
    }

    50% {
        opacity: 0;
        transform: translateX(-6.944vw);
    }

    75% {
        opacity: 0;
        transform: translateX(-3.472vw);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

/* home csss */


.Home {
    width: 100%;
    height: 52vw;
    position: relative;
}

.Home_title_png {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: flex-start;
    /* center o'rniga flex-start */
    justify-content: center;
    background-color: transparent;
    padding: 0;
    margin: 0;
    top: -3.472vw;
}

.Home_title_png img {
    position: -webkit-sticky;
    /* Safari uchun */
    position: sticky;
    top: 0;
    /* Eng yuqori qismga yopishib qoladi */
    z-index: 0;
    width: 31.806vw;
    height: auto;
    transform: translateY(-2.083vw);
}

.Home_title_png .imgtop {
    transform: translateY(-6vw);
}





/* .Home_title_png .fixed {
    position: fixed;
    top: -20px;
    z-index: -1;
} */

/* images header style */

.Home_Images_userImg {
    width: 100%;
    height: 100vh;
}

.Home_Images_userImg img {
    position: absolute;
    bottom: 0.278vw;
    z-index: 2;
    width: 42vw;
    transform: translateX(-5vw);
}

.Home_Images_ranchImage {
    width: 100%;
    height: 100vh;
}

.Home_Images_ranchImage_after {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.Home_Images_ranchImage_before {
    width: 100%;
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: 1s;
}

.Home_Images_ranchImage_before:hover {
    opacity: 1;
}

.Home_Images_ranchImage_after img {
    width: 100%;
}

.Home_Images_ranchImage_before img {
    width: 100%;
}

.headerContact {
    z-index: 3;
    width: 90%;
    position: absolute;
    bottom: 2.778vw;
    left: 5%;
    display: flex;
    align-items: center;
}

.headerContact_contact {
    width: 22.222vw;
    height: 2.778vw;
    border-radius: 2.778vw;
    border: 0.069vw solid #fff;
    gap: 2.083vw;
    display: flex;
    backdrop-filter: blur(1vw);
    /* Blur effekti */
    background-color: rgba(255, 255, 255, 0.69);
    /* Fonsiz effekt uchun orqa fon rangi va qatnashish o'rni (opacity) */
    align-items: center;
}

.headerContact_contact_phone {
    display: flex;
    margin-left: 1.389vw;
    gap: 0.347vw;
}

.headerContact_contact_phone p {
    font-size: 0.972vw;
    font-weight: 700;
    color: #137DFA;
    font-family: sans-serif;
}

.headerContact_contact_mIcon {
    display: flex;
    align-items: center;
    gap: 0.694vw;
    margin-top: 0.347vw;
}

.headerContact_contact_mIcon img {
    width: 1.667vw;
}

.headerContact_contact_phone img {
    width: 1.667vw;
}

.headerContact_line {
    width: calc(100% - 22.222vw);
    height: 0.139vw;
    background-color: #fff;
}

.Home_Images_logo {
    width: 58vw;
    z-index: -1;
    position: absolute;
    top: 3.472vw;
    right: 6.944vw;
    display: flex;
    gap: -6.944vw;
}

.Home_Images_logo img:nth-child(1) {
    width: 12vw;
    transform: translateX(31.25vw) translateY(-6.944vw);
}

.Home_Images_logo img:nth-child(2) {
    width: 20vw;
    transform: translateX(19.097vw) translateY(-3.472vw);
}

.Home_Images_logo img:nth-child(3) {
    width: 28vw;
}

.home_about {
    transform: translateY(-4.167vw);
    width: 100%;
    height: 100%;
    background-color: #F1F1F1;
    z-index: 9;
    clip-path: polygon(100% 0, 100% 89%, 50% 100%, 0 89%, 0 0);
}

.home_about_about {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_about_about_left {
    transition: 1s;
    width: 6.944vw;
    display: flex;
    transform: rotateY(180deg) translateX(-22.222vw);
    position: relative;
    align-items: center;
    justify-content: center;
}

.home_about_about_left img:nth-child(1) {
    width: 12vw;
    position: absolute;
    transform: translateX(13.889vw) translateY(-6.944vw);
}

.home_about_about_left img:nth-child(2) {
    width: 19vw;
    position: absolute;
    transform: translateX(17.361vw) translateY(-3.472vw);
}

.home_about_about_left img:nth-child(3) {
    width: 27vw;
    position: absolute;
    transform: translateX(21.528vw);
}

.home_about_about_right {
    transition: 1s;
    width: 6.944vw;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    transform: translateX(-22.222vw);
}

.home_about_about_right img {
    transition: 1s;
}

.home_about_about_left img {
    transition: 1s;
}

.home_about_about_right img:nth-child(1) {
    width: 12vw;
    position: absolute;
    transform: translateX(13.889vw) translateY(-6.944vw);
}

.home_about_about_right img:nth-child(2) {
    width: 19vw;
    position: absolute;
    transform: translateX(17.361vw) translateY(-3.472vw);
}

.home_about_about_right img:nth-child(3) {
    width: 27vw;
    position: absolute;
    transform: translateX(21.528vw);
}


.home_about_about:hover .home_about_about_left {
    transform: rotateY(180deg) translateX(-3.472vw);
}

.home_about_about:hover .home_about_about_left img:nth-child(1) {
    transform: translateX(11.806vw) translateY(-13.194vw);
}

.home_about_about:hover .home_about_about_left img:nth-child(2) {
    transform: translateX(16.319vw) translateY(-7.639vw);
}

.home_about_about:hover .home_about_about_right {
    transform: translateX(-3.472vw);
}

.home_about_about:hover .home_about_about_right img:nth-child(1) {
    transform: translateX(11.806vw) translateY(-13.194vw);
}

.home_about_about:hover .home_about_about_right img:nth-child(2) {
    transform: translateX(16.319vw) translateY(-7.639vw);
}

.home_about_about_main {
    display: flex;
    flex-direction: column;
    margin-top: -13.889vw;
    transition: 1.1s;
    transform: scale(0);
    opacity: 0;
    line-height: 2.083vw;
}

.home_about_about:hover .home_about_about_main {
    transform: scale(1);
    opacity: 1;
    line-height: 5vw;
}

.home_about_about_main div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: zonaProBold;
}

.home_about_about_main div span {
    color: #FF6400;
    font-weight: 700;
    font-size: 7.639vw;
}

.home_about_about_main_thin {
    color: #060919;
    font-weight: 700;
    font-size: 1.111vw;
    font-family: sans-serif;
}

.home_about_title {
    text-align: center;
    color: #000000;
    font-weight: 800;
    font-size: 3.889vw;
    font-family: sans-serif;
    text-transform: uppercase;
    opacity: 1;
    transition: 1s;
    transform: translateY(5.556vw);
}

.home_about:hover .home_about_title {
    opacity: .3;
    transform: translateY(-0.2vw);
}

.home_about_Con {
    width: 100%;
    height: 100vh;
    background-color: #fff;
}

.home_zyafka {
    width: 100%;
    height: auto;
    background: #fff;
    padding-bottom: 1.389vw;
}

.home_zyafka_box {
    position: relative;
    width: 80%;
    height: 30.347vw;
    margin: 0 auto;
    background: linear-gradient(270deg, #0157FF 0%, #2CB3FF 100%);
    display: flex;
    flex-direction: row;
    border-radius: 2.361vw;
    align-items: center;
}

.home_zyafka_box_rigth {
    width: 50%;
}

.home_zyafka_box_rigth img {
    width: 38.819vw;
    position: absolute;
    top: none;
    bottom: 0px;
}

.home_zyafka_box_left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3.472vw;
}

.home_zyafka_box_left_texts p:nth-child(1) {
    font-size: 3.333vw;
    font-weight: 600;
    color: #FFFFFF;
    font-family: "Montserrat", sans-serif;
    letter-spacing: -0.139vw;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

.home_zyafka_box_left_texts p:nth-child(2) {
    width: 80%;
    font-family: "Montserrat", sans-serif;
    color: #FFFFFF;
    font-size: 1.111vw;
}

.home_zyafka_box_left button {
    width: 16.944vw;
    height: 5vw;
    border-radius: 5vw;
    background: radial-gradient(127.68% 423.02% at 31.82% -13.39%, #FF9B00 12.5%, #ED1C24 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    box-shadow: 0px 1.042vw 2.5vw 0px #F657144D;
    border: none;
    font-weight: 700;
    font-size: 1.111vw;
    font-family: "Montserrat", sans-serif;
    color: #FFFFFF;
    cursor: pointer;
}

.resurce {
    width: 100%;
    height: auto;
    background-color: #F1F1F1;
    padding: 1.389vw 0;
    padding-bottom: 13.889vw;
}

.resurce_con {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2.083vw;
}

.resurce_title {
    font-family: "Montserrat", sans-serif;
    font-size: 3.75vw;
    font-weight: 700;
    color: #000000;
    text-transform: uppercase;
    margin: 0;
    padding: 1.389vw 0;
}

.resurce_boxes {
    display: flex;
    flex-direction: column;
    gap: 1.389vw;
}

.resurce_boxes_top {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.389vw;
}

.resurce_boxes_top_box {
    position: relative;
    height: 15.625vw;
    border-radius: 2.222vw;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex: 1;
    box-shadow: 0px 0px 7.5vw 0px #0000001A;
}

.resurce_boxes_top a {
    flex: 1;
    text-decoration: none;
}

.skoroBlur::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.716);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4vw;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    z-index: 1;
    border-radius: 2.222vw;
    text-decoration: none;
    list-style-type: none;
    color: black;
}

.skoroBlur::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.716); /* Background rangini yarim shaffof qiling */
    filter: blur(1px); /* Faqat background blur bo'ladi */
    z-index: 1; /* Bu matnning orqasida bo'lishi uchun z-index pastroq bo'lishi kerak */
    border-radius: 2.222vw;
}


.skoroBlur h4 {
    z-index: 3;
    font-size: 4vw;
    color: black;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    margin-left: 3vw;
}


.resurce_boxes_top_box p {
    width: 50%;
    font-family: "Montserrat", sans-serif;
    font-size: 1.667vw;
    font-weight: 700;
    color: #000000;
    margin-left: 1.389vw;
    z-index: 1;
}

.resurce_boxes_top_box img {
    width: 100%;
    margin-right: 1.389vw;
    transform: translateY(4.167vw) translateX(-3.472vw);
}

.resurce_boxes_bottom {
    display: flex;
    justify-content: space-between;
    gap: 1.389vw;
}

.resurce_boxes_bottom_box {
    position: relative;
    height: 15.625vw;
    border-radius: 2.222vw;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    flex: 1;
    box-shadow: 0px 0px 7.5vw 0px #0000001A;
}

.resurce_boxes_bottom_box p {
    width: 50%;
    font-family: "Montserrat", sans-serif;
    font-size: 1.667vw;
    font-weight: 700;
    color: #000000;
    margin-left: 1.389vw;
}

/* news css ===================================================================================================== */

.news {
    position: relative;
    width: 33%;
    margin: 0 auto;
    background-color: #FFFFFF;
    padding: 0.694vw;
    display: flex;
    margin-top: -6.944vw;
}

.sticky-element {
    margin-top: 6.944vw;
    position: -webkit-sticky;
    position: sticky;
    top: 12.5vw;
    bottom: 12.5vw;
    background-color: transparent;
    padding: 0.694vw;
    font-size: 1.389vw;
    z-index: 1;
    height: 47.569vw;
    width: 9.722vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transform: rotate(90deg) rotateY(180deg) rotateX(180deg) translateY(16.667vw) translateX(5.556vw);
}

.sticky-element span {
    font-size: 8.75vw;
    text-transform: uppercase;
    /* transform: rotate(180deg) rotateX(180deg) rotateY(180deg) translateX(0); */
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    background: transparent;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-position-x: 20.833vw;
    /* -webkit-text-stroke: 2px black; */
    z-index: 3;
}

.news_content {
    height: auto;
    padding: 1.389vw;
    display: flex;
    flex-direction: column;
    gap: 2.083vw;
}

.news_content_boxes {
    width: 26.389vw;
    height: 47.917vw;
    border-radius: 2.222vw;
    background-color: #F1F1F1;
    padding: 1.389vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.news_content_boxes a {
    width: 100%;
    height: 100% auto;
    flex-grow: 1;
}

.news_content_boxes img {
    width: 100%;
    height: 100%;
    border-radius: 1.111vw;
    object-fit: cover;
    flex-grow: 1;
}

.news_content_boxes .news_content_boxes_title {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 1.25vw;
}

.news_content_boxes_footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.news_content_boxes_footer span {
    display: flex;
    align-items: center;
    gap: 0.208vw;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    font-size: 1.111vw;
}


.newsBtn {
    position: absolute;
    bottom: 2.083vw;
    left: -2.778vw;
    width: 14.861vw;
    height: 5vw;
    background: radial-gradient(127.68% 423.02% at 31.82% -13.39%, #FF9B00 12.5%, #ED1C24 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    box-shadow: 0px 1.042vw 2.5vw 0px #F657144D;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.111vw;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    border-radius: 5vw;
    color: #FFFFFF;
    cursor: pointer;
}

.newsTop {
    width: 100%;
    height: 15.278vw;
    background-image: url('../../image/newsGr.svg');
    background-color: transparent;
    transform: translateY(-6.944vw);
    /* background-position-x: -20px; */
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.newsTopTitle {
    position: relative;
    transform: translateY(1.389vw) translateX(1.042vw);
    display: flex;
    align-items: center;
    justify-content: center;
}

.newsTopTitle img:nth-child(1) {

}

.newsTopTitle img:nth-child(2) {
    width: 12.847vw;
    animation: animTextR 10s infinite ease-in-out;
}


.newsTopTitle .uznewsimg {
    width: 13.653vw;
    position: absolute;
    animation: animText 10s infinite ease-in-out;
    z-index: 1;
}
.newsTopTitle .runewsimg {
    width: 9.653vw;
    position: absolute;
    animation: animText 10s infinite ease-in-out;
    z-index: 1;
}

.newsTopTitle .ennewsimg {
    width: 13.653vw;
    position: absolute;
    animation: animText 10s infinite ease-in-out;
    z-index: 1;
}

@keyframes animText {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes animTextR {
    0% {
        transform: rotate(-360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.study {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.083vw;
    margin: 1.389vw 0px;
    margin-top: 1.694vw;
    padding-top: 1.389vw;
}

.study_title {
    text-align: center;
    color: #060919;
    font-size: 3.75vw;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    padding: 0;
    margin: 0;
}

.study_btnBottm {
    width: 80%;
    height: 5vw;
    border-radius: 4.167vw;
    background: radial-gradient(127.68% 423.02% at 31.82% -13.39%, #FF9B00 12.5%, #ED1C24 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    box-shadow: 0px 1.042vw 2.5vw 0px #F657144D;
    font-weight: 700;
    font-size: 1.667vw;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
}

.study_btnBottm button {
    width: 100%;
    height: 5vw;
    border-radius: 4.167vw;
    background: radial-gradient(127.68% 423.02% at 31.82% -13.39%, #FF9B00 12.5%, #ED1C24 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    box-shadow: 0px 1.042vw 2.5vw 0px #F657144D;
    font-weight: 700;
    font-size: 1.667vw;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
}

.study_btnAnim {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.389vw;
    background-color: #FFFFFF;
}

.study_btnAnim_line {
    overflow: hidden;
    display: flex;
    gap: 1.389vw;
    animation: mrAnim 8s infinite;
    /* Adjust duration as needed */
    transform: translateX(-10.417vw);
}

.study_btnAnim_line_R {
    overflow: hidden;
    display: flex;
    gap: 1.389vw;
    animation: mrAnim_R 8s infinite;
    /* Adjust duration as needed */
    transform: translateX(10.417vw);
}

.study_btnAnim_line button {
    padding: 1.111vw 2.778vw 1.111vw 2.778vw;
    border-radius: 6.667vw;
    display: flex;
    align-items: center;
    gap: 0.694vw;
    font-weight: 700;
    color: #000000;
    font-family: "Montserrat", sans-serif;
    background: #F1F1F1;
    border: none;
}

.study_btnAnim_line_R button {
    padding: 1.111vw 2.778vw 1.111vw 2.778vw;
    border-radius: 6.667vw;
    display: flex;
    align-items: center;
    gap: 0.694vw;
    font-weight: 700;
    color: #000000;
    font-family: "Montserrat", sans-serif;
    background: #F1F1F1;
    border: none;
}

@keyframes mrAnim {
    0% {
        transform: translateX(-10.417vw);
    }

    50% {
        transform: translateX(10.417vw);
    }

    100% {
        transform: translateX(-10.417vw);
    }
}

@keyframes mrAnim_R {
    0% {
        transform: translateX(10.417vw);
    }

    50% {
        transform: translateX(-10.417vw);
    }

    100% {
        transform: translateX(10.417vw);
    }
}

/* plan styles ============================================================================================================== */


.plan {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -1.389vw;
}


.plan_video {
    padding: 3.472vw 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.plan_video iframe {
    width: 64.375vw;
    height: 33.333vw;
    transform: translateY(10.417vw);
    z-index: 1;
    position: sticky;
    /* Ensure the video img is sticky */
    top: 3.472vw;
    /* Adjust as needed to align with your design */
    z-index: 2;
    border-radius: 20px;
    /* Ensure it stays above other content */
}


.plan_imageUn {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateY(-6.944vw);
}

.plan_imageUn img:nth-child(1) {
    width: 100%;
    position: sticky;
    top: 34.722vw;
    z-index: 0;
}

.plan_imageUn img:nth-child(3) {
    width: 100%;
    top: -23.819vw;
    position: relative;
    z-index: 1;
}

.plan_indfos {
    min-width: 90%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    background-color: #000000;
    min-height: 20.833vw;
    transform: translateY(-20.833vw);
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 0px 5%;
    padding-bottom: 6.944vw;
}

.plan_indfos_boxes {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4.167vw;
    color: white;
}

.plan_indfos_box {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2.778vw;
}

.plan_indfos_box:nth-child(2) {
    flex-direction: row-reverse;
}

.plan_indfos_box:nth-child(2) .plan_indfos_box_left {
    text-align: start;
    align-items: flex-start;
}


.plan_indfos_box:nth-child(4) {
    flex-direction: row-reverse;
}

.plan_indfos_box:nth-child(4) .plan_indfos_box_left {
    text-align: start;
    align-items: flex-start;
}

.plan_indfos_box_left {
    height: 11.806vw;
    width: 22.361vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: end;
}

.plan_indfos_box_left h1 {
    padding: 0;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 6.111vw;
    font-weight: 900;
}

.plan_indfos_box_left p {
    padding: 0;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 1.111vw;
    font-weight: 700;
    color: #555555;
}

.plan_indfos_box_right {
    min-width: 39.792vw;
    max-width: 39.792vw;
    height: 22.222vw;
    overflow-y: scroll;
}

/* Hide the scrollbar in WebKit browsers (Chrome, Safari) */
.plan_indfos_box_right::-webkit-scrollbar {
    width: 0px;
    /* Hides the scrollbar */
}

/* Hide the scrollbar in Firefox */
.plan_indfos_box_right {
    scrollbar-width: none;
    /* Hides the scrollbar */
}

/* Hide the scrollbar in Internet Explorer 10+ */
.plan_indfos_box_right {
    -ms-overflow-style: none;
    /* Hides the scrollbar */
}


.plan_indfos_box_right img {
    width: 39.792vw;
}

.plan_indfos_right {
    width: 10.417vw;
    height: 46.111vw;
    background-color: transparent;
    top: 27.778vw;
    position: sticky;
    position: -webkit-sticky;
    transform: rotate(180deg);
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
}


.plan_indfos_right span {
    font-size: 4.722vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    padding: 0;
    margin: -0.556vw 0;
    color: white;
    transform: rotate(90deg);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.partnor {
    width: 100%;
    background-color: #FFFFFF;
    transform: translateY(-13.889vw);
    padding-bottom: 6.944vw;
}

.partnor_title {
    color: #0609194D;
    font-size: 2.222vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
}

.marqueeContainer {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    position: relative;
}

.marqueeContent {
    display: inline-block;
    animation: marquee 6s linear infinite;
    position: relative;
    display: flex;
    align-items: center;
    gap: 1.389vw;
}

.marqueeContent img {
    margin: 0px 0.694vw;
    width: 10vw;
}

@keyframes marquee {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-50%);
    }
}

.footer {
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    background-color: #fff;
    margin-top: -13.889vw;
}

.map {
    width: 100%;
    bottom: 0;
    left: 0;
}

.map iframe {
    width: 100%;
    height: 80vh;
}

.mapForm {
    position: absolute;
    left: 5%;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 0.694vw;
    display: flex;
    flex-direction: column;
    padding: 0.694vw 1.389vw;
    transform: translateY(50%);
}

.mapForm .mapForm_title {
    font-size: 1.944vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin: 0.694vw 0;
}

.mapForm a button {
    background-color: transparent;
    border: 0.069vw solid #7b787895;
    padding: 0.694vw 1.042vw;
    border-radius: 2.431vw;
    margin: 0.347vw 0;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    text-decoration: none;
    font-weight: 700;
    gap: 0.139vw;
    cursor: pointer;
    font-size: 1vw;
}

.mapForm a button svg {
    font-size: 1.25vw;
}

.mapForm a {
    text-decoration: none;
    list-style-type: none;
}

.mapForm_info {
    padding: 0.694vw 0;
}

.mapForm_info p {
    padding: 0;
    margin: 0.347vw 0;
}

.mapForm_info p:nth-child(1) {
    font-family: "Montserrat", sans-serif;
    font-size: 0.972vw;
    font-weight: 700;
    color: #7e7b7bce;
}

.mapForm_info p:nth-child(2) {
    font-family: "Montserrat", sans-serif;
    font-size: 0.972vw;
    font-weight: 700;
    color: #000000;
}

.mapForm_info p:nth-child(3) {
    font-family: "Montserrat", sans-serif;
    font-size: 0.972vw;
    font-weight: 700;
    color: red;
}

.plan_imageUn img:nth-child(2) {
    display: none;
}

.plan_imageUn img:nth-child(4) {
    display: none;
}



/* -------------------------------- */
/* -------------------------------- */

/* ====================================================================== */

/* =================================================   mobile respanseive   ============================================================================ */

/* ====================================================================== */

/* -------------------------------- */
/* -------------------------------- */




@media screen and (max-width: 768px) {

    /* .rightMenu {
        margin-top: 90.083vw;
    } */
    .Home_title_png .imgRus {
        transform: translateY(4vw);
     }  
    .rightMenuStudent button {
        transform: translateY(160%);
        width: 10vw;
        height: 54.722vw;
    }

    .rightMenuMenu button {
        transform: translateY(240%);
        width: 10vw;
        height: 30.722vw;
    }

    .rightMenu button span {
        font-size: 5vw;
        transform: rotate(270deg) translateX(.6994vw) translateY(-4vw);
    }

    .rightMenuMenu button span {
        font-size: 5vw;
        transform: rotate(270deg) translateX(1.5vw) translateY(15vw);
    }

    .rightMenuStudent button img {
        width: 10vw;
    }

    .rightMenuMenu button img {
        width: 9vw;
    }

    .rightMenuMenu_menu_menus {
        top: 26vw;
        left: 15vw;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 80%;
        height: 70%;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .rightMenuMenu_menu_menus ul {
        gap: 1vw;
    }

    .rightMenuMenu_menu_menus ul span {
        font-size: 4.5vw;
    }

    .rightMenuMenu_menu_menus ul li {
        font-size: 3.5vw;
    }

    .rightMenuMenu_menu .rightMenuCloseBtn {
        top: 40vw;
    }

    .rightMenuMenu_menu .rightMenuCloseBtn span {
        transform: rotate(0deg) translateX(3vw) translateY(10vw);
        font-size: 6vw;
    }

    .rightMenuMenu_menu .rightMenuCloseBtn img {
        width: 10vw;
    }

    .rightMenuStudent .rightMenuCloseBtn {
        top: 30vw;
    }

    .rightMenuStudent .rightMenuCloseBtn span {
        transform: "";
        font-size: 6vw;
        margin-top: 5vw;
    }

    .rightMenuStudent .rightMenuCloseBtn img {
        width: 10vw;
    }

    /* home */

    .Home_Images_logo {
        display: none;
    }

    .Home {
        height: 170vw;
    }

    .Home_title_png img {
        width: 83.515vw;
    }

    .Home_Images_userImg img {
        width: 105vw;
        transform: translateX(-25.5vw) translateY(-4vw);
    }

    .Home_Images_ranchImage_after {
        overflow: hidden;
    }

    .Home_Images_ranchImage_before {
        overflow: hidden;
    }

    .Home_Images_ranchImage_after img {
        width: 300vw;
        transform: translateX(-35%);
    }

    .Home_Images_ranchImage_before img {
        width: 300vw;
        transform: translateX(-35%);
    }

    .headerContact_line {
        width: calc(100% - 65.55vw);
        height: 0.339vw;
        transform: translateY(-3vw);
    }

    .headerContact_contact {
        width: 64.55vw;
        height: 6.945vw;
        border-radius: 6.945vw;
        transform: translateY(-3vw);
    }

    .headerContact_contact_phone {
        margin-left: 3.472vw;
        gap: 0.868vw;
    }

    .headerContact_contact_phone p {
        font-size: 3.43vw;
    }

    .headerContact_contact_mIcon {
        gap: 1.735vw;
        margin-top: 0.868vw;
    }

    .headerContact_contact_mIcon img {
        width: 5.192vw;
    }

    .headerContact_contact_phone img {
        width: 5.192vw;
    }

    .home_about {
        height: 177vw;
        overflow: hidden;
    }

    .home_about_about {
        transform: scale(3.5);
        height: 140vw;
    }

    .home_about_title {
        font-size: 42px;
        transform: translateY(-20px);
    }

    .home_about:hover .home_about_title {
        color: transparent;
        opacity: 0;
    }

    .home_zyafka {
        margin-bottom: 10vw;
    }

    .home_zyafka_box {
        width: 90%;
        height: 130.347vw;
        flex-direction: column-reverse;
        border-radius: 6.361vw;
    }

    .study_btnBottm button {
        width: 100%;
        height: 100%;
        border-radius: 15vw;
        font-size: 5vw;
    }

    .home_zyafka_box_rigth img {
        width: 90vw;
        top: -30vw;
        left: 0;
    }

    .home_zyafka_box_left {
        width: 90%;
    }

    .home_zyafka_box_left_texts p:nth-child(1) {
        font-size: 6.5vw;
    }

    .home_zyafka_box_left_texts p:nth-child(2) {
        font-size: 3.5vw;
    }

    .home_zyafka_box_left button {
        width: 62vw;
        height: 15vw;
        border-radius: 15vw;
        font-size: 4.111vw;
        margin-bottom: 5vw;
    }

    .resurce_title {
        margin-top: 10vw;
        margin-bottom: 5vw;
        font-size: 8.889vw;
        text-align: center;
    }

    .resurce_boxes {
        height: auto;
        gap: 3vw;
    }

    .resurce_boxes_top {
        flex-direction: column;
        justify-content: flex-start;
        flex: none;
        gap: 3vw;
    }

    .resurce_boxes_top_box {
        height: 50.625vw;
        border-radius: 7vw;
        flex: none;
    }

    .skoroBlur::after {
        border-radius: 7vw;
    }
    .skoroBlur::before {
        border-radius: 7vw;
    }


    .resurce_boxes_top_box p {
        font-size: 5.5vw;
        margin: 0 5vw;
    }

    .resurce_boxes_bottom {
        flex-direction: column;
        gap: 3vw;
    }

    .resurce_boxes_bottom_box {
        height: 50.625vw;
        border-radius: 7vw;
        flex: none;
    }

    .resurce_boxes_bottom_box p {
        font-size: 5.5vw;
        margin: 0 5vw;
    }

    .news {
        width: 97%;
        flex-direction: column;
        align-items: center;
        gap: 20vw;
    }

    .sticky-element {
        position: relative;
        height: 59vw;
        margin: 25vw;
        /* justify-content: center;
        align-items: center; */

    }

    .sticky-element span {
        font-size: 22vw;
    }

    .news_content {
        margin-bottom: 35vw;
    }

    .news_content_boxes {
        width: auto;
        height: 75.917vh;
        padding: 5.389vw;
        border-radius: 5.222vw;
    }

    .news_content_boxes img {
        border-radius: 4.111vw;
    }

    .news_content_boxes .news_content_boxes_title {
        font-size: 3.25vw;
    }

    .news_content_boxes_footer span {
        font-size: 3.111vw;
        gap: 1.208vw;
    }

    .newsBtn {
        width: 50%;
        height: 15vw;
        font-size: 3.111vw;
        border-radius: 15vw;
        bottom: 10vw;
        left: 25%;
    }

    .newsTopTitle img:nth-child(1) {
        /* width: 24.653vw; */
        position: absolute;
        animation: animText 10s infinite ease-in-out;
        z-index: 1;
    }

    .newsTopTitle .uznewsimg {
        width: 32vw;
    }

    .newsTopTitle .runewsimg {
        width: 24vw;
    }

    .newsTopTitle .ennewsimg {
        width: 34vw;
    }

    .newsTopTitle img:nth-child(2) {
        width: 31.847vw;
        animation: animTextR 10s infinite ease-in-out;
    }

    .newsTop {
        background-size: 200%;
        height: 38.278vw;
        transform: translateY(-6.944vw);
        background-position-x: -51.544vw;
    }

    .study {
        gap: 10vw;
    }

    .study_title {
        font-size: 8.75vw;
    }

    .study_btnBottm {
        width: 100%;
    }

    .study_btnAnim {
        gap: 3.2vw;
    }

    .study_btnAnim_line {
        gap: 3.2vw;
    }

    .study_btnAnim_line_R {
        gap: 3.2vw;
    }

    .study_btnAnim_line button {
        padding: 3.111vw 4.778vw;
        border-radius: 8.667vw;
    }

    .study_btnAnim_line_R button {
        padding: 3.111vw 4.778vw;
        border-radius: 8.667vw;
    }

    .study_btnBottm {
        width: 95%;
        height: 15vw;
        font-size: 5.067vw;
        border-radius: 14.167vw;
        text-transform: uppercase;
    }

    .plan_video {}

    .plan_video img {
        width: 100%;
        height: 48.542vw;
        object-fit: contain;
    }

    .plan_imageUn {}

    .plan_imageUn img:nth-child(1) {
        display: none;
    }

    .plan_imageUn img:nth-child(3) {
        display: none;
    }

    .plan_imageUn img:nth-child(2) {
        display: flex;
        width: 100%;
        position: sticky;
        top: 64.722vw;
        z-index: 0;
    }

    .plan_imageUn img:nth-child(4) {
        display: flex;
        width: 100%;
        height: auto;
        top: -82vw;
        position: relative;
        z-index: 1;
    }

    .plan_indfos {
        width: auto;
        transform: translateY(-45.833vw);
        flex-direction: column-reverse;
        gap: 10vw;
    }

    .plan_indfos_boxes {
        width: 100%;
    }

    .plan_indfos_box {
        width: 100%;
        flex-direction: column;
    }

    .plan_indfos_box:nth-child(2) {
        flex-direction: column;
    }

    .plan_indfos_box:nth-child(4) {
        flex-direction: column;
    }

    .plan_indfos_box_right {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: 50vw;
    }

    .plan_indfos_box_right img {
        width: 100%;
        height: auto;
    }

    .plan_indfos_box_left {
        height: 52.806vw;
        width: 100%;
    }

    .plan_indfos_box_left h1 {
        font-size: 21.458vw;
    }

    .plan_indfos_box_left p {
        font-size: 4.111vw;
    }

    .plan_indfos_right span {
        font-size: 22vw;
        height: 50vw;
        transform: rotate(90deg) translateY(140%);
    }

    .plan_indfos_right {
        position: relative;
        height: 220vw;
        margin-bottom: 40vw;
    }

    .partnor {
        transform: translateY(-35vw);
    }

    .partnor_title {
        font-size: 8.75vw;
    }

    .marqueeContent img {
        width: 60vw;
    }

    .map iframe {
        height: 60vh;
    }

    .mapForm {
        left: 3%;
        width: 85%;
        height: 40vh;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin: 0 auto;
        border-radius: 5.694vw;
        padding: 3.694vw 4.389vw;
        transform: translateY(10%);
    }

    .mapForm .mapForm_title {
        font-size: 5.944vw;
    }

    .mapForm a button {
        padding: 3.694vw 5.042vw;
        font-size: 4vw;
        border-radius: 7.431vw;
        margin: 2vw 0;
    }

    .mapForm a button svg {
        font-size: 4.25vw;
    }

    .mapForm_info p:nth-child(1) {
        font-size: 3.972vw;
    }

    .mapForm_info p:nth-child(2) {
        font-size: 3.972vw;
    }

    .mapForm_info p:nth-child(3) {
        font-size: 3.972vw;
    }

    .rightMenuStudent_menu_info {
        width: 70%;
        height: 120vw;
        flex-direction: column;
        border-radius: 6vw;
    }

    .rightMenuStudent_menu_info_left h1 {
        width: 100%;
        margin-top: 10vw;
        font-size: 7vw;
    }

    .rightMenuStudent_menu_info_left button {
        transform: none;
        width: 18.333vw;
        height: 10vw;
        font-size: 2.5vw;
        font-weight: 700;
        border-radius: 10vw;
    }

    .rightMenuStudent_menu_info_right img {
        width: 60%;
        transform: translateY(10vw) translateX(10vw);
    }
}